import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import { Card } from '../../../Card';
import DukaanCard from '@/components/DukaanCard';
import { Listing, ListingHeading } from '../Hybrid.styles';
import MobileDsAds from './MobileDsAds';
import { getProducts } from '../utils';
import FlatList from '@/components/FlatList';
import LoaderIcon from '@/components/Common/LoaderIcon';
import {
  resetCatalogsList,
  setMB5SelectedCategoryPaginationConfig,
} from '@/redux/actions';
import { scrollOnPageLoad } from '@/utils/scrollOnPageLoad';
import { CLICKED_ITEM_OBJ } from '@/utils/constants';

export const SelectedCategoryItems = (props) => {
  const router = useRouter();
  const dispatch = useDispatch();
  const [isProductsLoading, setIsProductsLoading] = useState(false);
  const [canLoadProducts, setCanLoadProducts] = useState(false);

  const {
    iteratableCategories,
    showATCBtn,
    onItemClick,
    categorySelected,
    activeCategoryIndex,
  } = props;

  const {
    catalogData: { catalog_items, productTags },
    commonData: { loader },
    state,
  } = useSelector((state) => {
    const catalogData = state.catalogReducer;
    return {
      catalogData,
      commonData: state.commonReducer,
      state: catalogData.mb5PaginationData.selectedCategoryComponent,
    };
  });

  const catID = router?.query?.cid || categorySelected;

  const selectedPaginationConfig = state[catID];
  const isNextPage = selectedPaginationConfig?.isNext;

  useEffect(() => {
    const scrollConfig = scrollOnPageLoad();
    if (scrollConfig.toScroll) {
      try {
        const { y_scroll } = scrollConfig;
        setTimeout(() => {
          sessionStorage.removeItem(CLICKED_ITEM_OBJ);
          window.scrollTo({ top: y_scroll });
        }, 300);
      } catch (err) {
        console.log('timeout time error:', err);
      }
    } else {
      dispatch(resetCatalogsList(catID));
      setState({ [catID]: { pageNo: 1, isNext: true } });
    }
    if (catID >= 0) {
      if (!selectedPaginationConfig) setState({ [catID]: { pageNo: 1, isNext: true } });
    }
    setCanLoadProducts(true);
  }, [catID, iteratableCategories]);

  const setState = (argu) => dispatch(setMB5SelectedCategoryPaginationConfig(argu));

  const loadProducts = async (pageNo) => {
    const { status: isListFetched, isNextPage } = await getProducts(
      catID,
      productTags.findIndex((tag) => tag.id === +catID) >= 0 ? 1 : 0,
      pageNo
    );
    return { isListFetched, isNextPage };
  };

  const setPageNumber = (pageNo, isNext) => setState({ [catID]: { pageNo, isNext } });

  const getItemName = (item) => item?.name;

  const loadMoreData = async () => {
    try {
      if (!isNextPage || loader) return;
      setIsProductsLoading(true);
      let nextPageNum = state[catID].pageNo || 1;
      const { isNextPage: isNext } = await loadProducts(nextPageNum);
      nextPageNum += 1;
      setPageNumber(nextPageNum, isNext);
    } catch (err) {
      console.log('Error:  ', err);
    } finally {
      setIsProductsLoading(false);
    }
  };

  if (!iteratableCategories?.length) {
    return null;
  }

  return (
    canLoadProducts && (
      <>
        <ListingHeading id={`listing-${categorySelected}`}>
          {getItemName(iteratableCategories[activeCategoryIndex]) || 'Available Items'}
        </ListingHeading>
        <Listing>
          <FlatList
            isLoaderVisible={isProductsLoading}
            isNext={isNextPage}
            onScrollEnd={loadMoreData}
            renderInsideFragment={true}
            renderList={() =>
              catalog_items[categorySelected]?.map((data, ind) => (
                <React.Fragment key={'items-card' + ind}>
                  <Card
                    key={'items-card' + ind}
                    data={data}
                    onItemClick={() => onItemClick(data)}
                    showButton={showATCBtn}
                    noTags={
                      productTags.findIndex((tag) => tag.id === +categorySelected) !== -1
                    }
                  />
                  <MobileDsAds data={data} />
                </React.Fragment>
              ))
            }
          />
          {loader && (
            <>
              <DukaanCard isLoader={true} />
              <DukaanCard isLoader={true} />
              <DukaanCard isLoader={true} />
              <DukaanCard isLoader={true} />
            </>
          )}
        </Listing>
        {isNextPage && (
          <div className="flex justify-center mt4 relative">
            <LoaderIcon style={{ transform: 'translateX(-50%)' }} />
          </div>
        )}
      </>
    )
  );
};
